import { getSession } from "./Login";
import { refreshToken } from "./Login";
import RefreshError from '../schemas/Exception/RefreshError';
import TimeoutError from '../schemas/Exception/TimeoutError';
import InternalError from '../schemas/Exception/InternalError';
import ExpiredAccountError from '../schemas/Exception/ExpiredAccountError';
import Config from '../config.json';


const BearerPrefix = 'Bearer ';
function apiVersion(version: number | undefined): string { return(version ? '/api/v' + version : '/api/v2'); }


function getHeaders(authorization: boolean, contentType: string | undefined = 'application/json;charset=UTF-8') {
  let headers: any = {};
  if (contentType !== 'application/x-zip-compressed' && contentType !== 'multipart/form-data')
    headers['content-type'] = contentType;
  const session = getSession();
  if (authorization && session)
    headers['Authorization'] = BearerPrefix + session.access_token;
  return headers;
}


function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


async function checkCriticalError(response: Response,
  defaultMessage: string | undefined,
  accessToken: string | undefined = undefined) {
  if (response.status === 504) {
    throw new TimeoutError();
  }
  if (response?.status === 500 || response?.status === 502) {
    let errorMessage = defaultMessage ? defaultMessage : 'Something went wrong, please try another search';
    throw Array.isArray(errorMessage) ? errorMessage[0].msg : errorMessage;
  }
  if (response?.status === 501) {
    let details = await response.json();
    let errorMessage = details ? details.detail : 'Functionality is not implemented yet';
    throw new InternalError(errorMessage);
  }
  if (response?.status === 422) {
    let details = await response.json();
    let errorMessage = details ? details.detail : defaultMessage;
    throw Array.isArray(errorMessage) ? errorMessage[0].msg : errorMessage;
  }
  if (response?.status === 403) {
    console.log('403 is received, url=', response.url);
    try {
    const details = await response.json();
    if (details && details.detail === 'Account expired')
      throw new ExpiredAccountError();
    

    if (response.url.includes('/user/token/refresh')) {
      localStorage.clear();
      throw new RefreshError(details.detail + ', session is closed.');
    }

  }
  catch {

  }
    const session = getSession();
    if (session?.access_token && session?.refresh_token == null) {
      console.log('another request is refreshing access token, currently refresh token is empty');
      await delay(Config.timeoutOnRefreshTokenSecond);
      return 'currently refresh token is empty';
    }
    console.log('calling refresh access token, currently refresh token=', session?.refresh_token, 'access token=', accessToken);
    await refreshToken(session?.refresh_token, accessToken);
  }
}


async function fetchPost(endPoint: string,
  body: string | FormData,
  useVersion: boolean = true,
  authorization: boolean = false,
  contentType: string | undefined = 'application/json;charset=UTF-8',
  defaultErrorMessage: string | undefined = undefined
): Promise<any> {
  let lastError: string = undefined
  let attempts = authorization ? 2 : 1;
  while (attempts > 0) {
    attempts--;
    const headers = getHeaders(authorization, contentType);
    const response = await fetch(buildUrl(useVersion, endPoint), {
      method: 'POST',
      headers: headers,
      body: body,
    });
    if (response?.ok)
      return response;
    else {
      if (endPoint === '/user/token/refresh')
        defaultErrorMessage = "Access token can not be refreshed";
      if (endPoint === '/user/account/login')
        return response;
      lastError =await checkCriticalError(response, defaultErrorMessage, headers['Authorization']);
    }
    if (attempts === 0) { 
      if (!response.ok) throw new Error(lastError ? lastError : 'Request failed');
      else return response; }
  }
}


function buildUrl(useVersion: boolean, endPoint: string, version: number | undefined = undefined): string {
  return (useVersion ? apiVersion(version) : '/api') + endPoint
}


async function fetchGet(endPoint: string, useVersion: boolean = true, authorization: boolean = false, version: number = undefined): Promise<any> {
  let attempts = authorization ? 2 : 1;
  while (attempts > 0) {
    attempts--;
    const response = await fetch(buildUrl(useVersion, endPoint, version), {
      method: 'GET',
      headers: getHeaders(authorization),
    });
    if (response.ok) return response;
    else
      await checkCriticalError(response, '');
    if (attempts === 0) return response;
  }
}


async function fetchPut(endPoint: string, body: string | FormData, useVersion: boolean = true,
  authorization = false): Promise<any> {
  let attempts = authorization ? 2 : 1;
  while (attempts > 0) {
    attempts--;
    const headers = getHeaders(authorization);
    const response = await fetch(buildUrl(useVersion, endPoint),
      {
        method: 'PUT',
        headers: headers,
        body: body,
      });
    if (response.ok) return response;
    else
      if (authorization && response.status === 403) {
        await refreshToken(headers['Authorization']);
      }
    if (attempts === 0) return response;
  }
}


async function fetchDelete(endPoint: string, useVersion: boolean = true,
  authorization = false): Promise<any> {
    let attempts = authorization ? 2 : 1;
    while (attempts > 0) {
      attempts--;
      const headers = getHeaders(authorization);
      const response = await fetch(buildUrl(useVersion, endPoint),
        {
          method: 'DELETE',
          headers: headers,
        });
      if (response.ok) return response;
      else
        if (authorization && response.status === 403) {
          await refreshToken(headers['Authorization']);
        }
      if (attempts === 0) return response;
    }
}


async function reportError(body: string): Promise<any> {
  const reportEndPoint = '/misc/report-error';
  return await fetchPost(reportEndPoint, body);
}

export { fetchGet, fetchPost, fetchPut, fetchDelete, reportError, BearerPrefix };