
import { io, Socket } from "socket.io-client";
import { getSession } from "../../services/Login";
import { refreshToken } from "../../services/Login";


class WebSocketConnection {
  private static instance: WebSocketConnection;
  private socket: Socket;

  private constructor() {
    this.initializeSocket();
  }

  private initializeSocket() {
    console.log("Initializing socket");
    const url = "https://dev.odanchem.org/";
    const path = "/api/v2/socket.io";
    const session = getSession();

    this.socket = io(url, {
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
      transports: ["websocket", "polling", "flashsocket"],
      auth: {
        'mode': session === undefined ? 'anonymous' : 'jwt',
        'jwt': session?.access_token
      },
      path,
    });

    this.socket.on("connect_error", async (err) => {
      //console.log("connect_error", err);
      if (err.message === "Signature has expired") {
        console.log("jwt expired");
        try {
          await this.refreshToken();
          console.log("jwt expired refreshed");
          const session = getSession();
          console.log("new session access token", session.access_token);

          this.initializeSocket();
          // this.socket = io(url, {
          //   extraHeaders: {
          //     "Access-Control-Allow-Origin": "*",
          //   },
          //   transports: ["websocket", "polling", "flashsocket"],
          //   auth: { 'jwt': session?.access_token },
          //   path,
          // });

          // this.socket.on("connect", () => {
          //   console.log("new socket connected after refresh token", this.socket.connected);
          //   this.initializeListeners();
          // });

          // this.socket.on("connect_error", (err) => {
          //   console.error("new socket connect_error", err);
          // });

        } catch (refreshErr) {
          console.error("Failed to refresh token", refreshErr);
          throw new Error("JWT signature has expired and token refresh failed");
        }
      }
    });

    this.socket.on("connect", () => {
      console.log("socket connected", this.socket.connected);
      this.initializeListeners();
    });

    this.socket.on("disconnect", () => {
      console.log("socket disconnected");
    });

    this.socket.on("reconnect_attempt", () => {
      console.log("socket reconnect_attempt");
    });

    this.socket.on('message', (data) => {
      console.error('MMM ' + data);
    });
      
    this.socket.on("reconnect", () => {
      console.log("socket reconnected");
    });

    //if (!this.socket.connected) {
    //  console.error("Socket is not connected");
    //}
  }


   public async refreshToken(): Promise<void> {
    
    const session = getSession();
    console.log("old session asscess token", session.access_token);
 
    //if (!session) {
    //  throw new Error("No session found");
    await refreshToken(session?.refresh_token, session.access_token);

   }




  public static getInstance(): WebSocketConnection {
    console.log("Getting instance of connection", WebSocketConnection.instance);  
    if (!getSession()?.access_token) { 
      console.error("No access token found for websocket connection");
      return null;
    }
    console.log("Creating new instance of connection", WebSocketConnection.instance);
    
    if (!WebSocketConnection.instance || !WebSocketConnection.instance.socket.connected) {
      WebSocketConnection.instance = new WebSocketConnection();
      //
    
    }
    console.log("Returning instance", WebSocketConnection.instance.socket);
    return WebSocketConnection.instance;
  }
1  
  public getSocket(): Socket {
    return this.socket;
  }

  private initializeListeners(): void {
    this.socket.on("connect", () => {
      console.log("connection established");
    });


    this.socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
  }

  
  public clearSearches(): void {
    console.log(this.socket.emit("/debug/clear"));
  }
}

export default WebSocketConnection.getInstance() as WebSocketConnection;