import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

import '../../App.css';
import { Item, Item2 } from '../../styles';
import AppSettings from '../../API/appSettings';
import { getSession } from '../../services/Login';
import Header from '../../components/HeaderComponent';
import Accept from '../../schemas/Compound/Accept';
import MoleculeView from '../../components/MoleculeView';
import ProgressControl from '../../components/ProgressControl';
import RefreshError from '../../schemas/Exception/RefreshError';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import { fetchGet, fetchPost } from '../../services/GettingData';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import IExactSearchResponse from '../../schemas/Compound/IExactSearchResponse';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import CompoundPropertiesComponent from '../../components/Compound/CompoundPropertiesComponent';
import CompoundSpectrumComponent from '../../components/Compound/CompoundSpectrumComponent';
import PublicationsComponent from '../../components/Publication/PublicationsComponent';
import WebSocketConnection from '../Sockets/WebSocketConnection';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import Config from '../../config.json';
import IGetResponse from '../../schemas/Compound/IGetResponse';
import { ICompoundSearchModel } from '../../schemas/Compound/ICompoundSearchModel';



export type ExactSearchProps = { svgContent: string, moleculeString: string };
// type ExactSearchState = {
//   isLoading: boolean,
//   isMoleculeInContainer: boolean,
//   compoundSearchResponse: IExactSearchResponse,
// };




export const ExactSearchPage: React.FC<ExactSearchProps> = (props) => {

  const [socketConnection, setSocketConnection] = React.useState(WebSocketConnection);
  const [search, setSearch] = React.useState<string>();
  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();
  //IGetResponse

  const getCompound = async (moleculeId: number): Promise<IExactSearchResponse> => {
    setLoading(true);
    const response = await fetchGet('/compound/id/' + moleculeId);
    return await response.json();
  }

  const getMolecule = (): string => (props as any).location?.state?.moleculeString;

  const [exactFilter, setExactFilter] = React.useState<{ structure: string }>({ structure: getMolecule() });



  React.useEffect(() => {

    console.log('useEffect socketConnection');
    if (socketConnection) {
      const connection = socketConnection.getSocket();
      console.log('subscription has started');
      //connection.on('/search/get', (data) => onGet(data));
      connection.on('/search/get', (packet) => onGet(packet));
      //connection.on('/error', (data) => onError(data));

      connection.on('/search/accepted', (packet) => onAccept(packet, connection));
      //subscribeToEvent('/search/accepted', (packet) => onAccept(packet));
      console.log('subscription has finished');

      return () => {
        connection.off('/search/accepted', onAccept);
        connection.off('/search/finish', onFinish);
      };
    }
    else console.log('subscription hasnt started, it is closed ');

  }, [socketConnection])



  //const [molecule, setMolecule] = React.useState<string>(getMolecule());
  //const [moleculeSvg, setMoleculeSvg] = React.useState<string>(getSVG());
  // const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<IExactSearchResponse>({
  //     id: '',
  //     svg: (props as any).location?.state?.svgContent ? (props as any).location?.state?.svgContent : '',
  //     molecule: '',
  //     properties: {},
  //     total_publications: 0,
  //     associated_spectra: []
  // });

  React.useEffect(() => {
    const startSearch = async () => {
      await startStructSearch();
    };
    startSearch();
    const fetchCompound = async () => {
      if ((props as any).match.params['molid']) {
        const response = await getCompound((props as any).match.params['molid']);
        setCompoundSearch(response as unknown as ICompoundSearchModel);
        setLoading(false);
      }
    };
    fetchCompound();
  }, [(props as any).match.params['molid']]);


  const [isLoading, setLoading] = React.useState<boolean>((props as any).match.params['molid'] ? true : false);
  const [isMoleculeInContainer, setiMoleculeInContainer] = React.useState<boolean>(false);
  const [compoundSearch, setCompoundSearch] = React.useState<ICompoundSearchModel>();


  const searchExact = async (): Promise<void> => {
    let molecule = getMolecule();
    if (!molecule) {
      console.warn('no molecule');
      (props as any).history.push('/compound-search');

    }
    setLoading(true);
    let compoundSearchResponse;
    try {
      const response = await fetchPost('/compound/search/exact', JSON.stringify(molecule), true, true);
      if (response.ok) {
        compoundSearchResponse = await response.json();
        if (!compoundSearchResponse) compoundSearchResponse = {};
      }
      else {
        if (response.status === 404)
          compoundSearchResponse = {};
      }
    }
    catch (e: any) {
      setLoading(false);
      setCompoundSearch(compoundSearchResponse);

      if (e instanceof ExpiredAccountError)
        (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });

      if (e instanceof RefreshError) {
        alert(e.message);
        (props as any).history.push({ pathname: '/login', state: { welcomeMessage: true } });
      }

      if (e instanceof TimeoutError) {
        alert(e.message);
      }
      else
        alert(e.toString());
    }

    setLoading(false);
    setCompoundSearch(compoundSearchResponse);
  }


  const onFinish = (packet: IFinishInfo, searchId: string) => {
    if (packet.id === searchId) {
      console.log('/search/finish our obtained ');
      setSearchStatus(SearchStatus.Finished);
    }
  }


  const onGet = (data: IGetResponse) => {
    console.log('obtained items', data.items);
    if (data.items.length === 0) {

    }
    setCompoundSearch(data.items[0]);
    setLoading(false);
  }

  const get = (currentPage: number = 1) => {
    if (search && socketConnection) {
      const connection = socketConnection.getSocket();
      console.log('get search with a page', search, currentPage);
      connection.emit("/search/get", {
        "id": search,
        "page": currentPage,
        'per_page': Config.itemsPerPage,
      });
    }
  }

  React.useEffect(() => {
    if (searchStatus === SearchStatus.Finished) {
      get();
    }
  }, [searchStatus]);



  const onAccept = (packet: Accept, connection: any) => {
    console.log('/search/accepted happens', packet.id);
    connection.on('/search/finish', (packet) => onFinish(packet, packet.id));
    setSearch(packet.id);
  }

  const startStructSearch = async (): Promise<boolean> => {
    if (socketConnection) {
      console.log('start search', exactFilter);
      setSearchStatus(SearchStatus.Started);
      setCompoundSearch(undefined);
      socketConnection.getSocket().emit("/search/start", { "type": "exact", "params": exactFilter });

    }
    return true;
  }


  React.useEffect(() => {
    if (socketConnection) {
 
    const fetchData = async () => {
      console.log('useEffect current page');
      await startStructSearch();
    };
    fetchData();
    }
  }, [socketConnection]);







  console.log('compoundSearch', compoundSearch);


  return (compoundSearch ?
    <Grid container md={12} spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>

      <Grid container xs={12}>
        <Header title='Exact Search' showLogin={getSession() !== undefined} helpAddress='help#wics' />
      </Grid>

      <Grid container xs={12}>
        <Grid container md={8} xs={12} style={{ display: 'flex' }}>
          <Grid xs={12} container>
            <Grid xs={12} container>
              <Grid xs={12} md={4}></Grid>
              <Grid md={8} xs={12} container>
                <Grid xs={12} container style={{ height: '100%' }}>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid md={4} xs={12}>
          <Item className='center-xs' style={{ width: '250px', marginLeft: 'auto' }}>
            <MoleculeView svgContent={compoundSearch.id ? compoundSearch?.svg : (props as any).location?.state?.svgContent} link={''} isMoleculeInContainer={true} />
          </Item>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={12}>
        </Grid>

        <Grid xs={12} spacing={1}>
          {compoundSearch.id &&
            <Item style={{ fontSize: '1em', paddingTop: '0px', paddingBottom: '0px', marginTop: '2em', textAlign: 'left', padding: '1em' }}>
              <Box sx={{ minHeight: '2em', width: '100%' }}>
                <CompoundSpectrumComponent svgContent={compoundSearch?.svg}
                  compoundId={compoundSearch.id}
                  spectra={compoundSearch.associated_spectra} />
              </Box>
            </Item>
          }
        </Grid>

        <Grid xs={12} spacing={1}>
          {compoundSearch.id &&
            <Item style={{
              fontSize: '1em',
              paddingTop: '0px',
              paddingBottom: '0px',
              marginTop: '2em',
              textAlign: 'left',
              padding: '1em'
            }}>
              <Box sx={{ minHeight: '2em', width: '100%' }}>
                <CompoundPropertiesComponent compoundId={compoundSearch.id}
                  propertiesCount={compoundSearch?.properties} />

              </Box>
            </Item>
          }
        </Grid>
        <Grid xs={12} style={{ marginBottom: '1em' }}>
          {compoundSearch.id &&
            <Item style={{ fontSize: '1em', paddingTop: '0px', paddingBottom: '0px', marginTop: '2em', textAlign: 'left', padding: '1em' }}>
              <Box sx={{ minHeight: '2em', width: '100%' }}>
                <div><span>Associated publications </span>{'(' + compoundSearch?.total_publications + ')'}</div>
                <PublicationsComponent compoundId={compoundSearch.id} />
              </Box>
            </Item>
          }
        </Grid>
        <Grid xs={12}>
          <div></div>
        </Grid>
      </Grid>
    </Grid> : null
  );

}
export default ExactSearchPage;